<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Paquete - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">

              <b-tabs content-class="mt-3">
                <b-tab title="Español" active>
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Categoria:">
                        <b-form-select disabled v-model="mpackage.id_package_category" :options="package_category_es" ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Codigo:">
                        <b-form-input disabled v-model="mpackage.code" ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Titulo:">
                        <b-form-input disabled v-model="mpackage.title_es" ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Descripción:">
                        <tinymce readonly :height="1000" v-model="mpackage.description_es" id='description_es'></tinymce>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Itinerario:">
                        <tinymce readonly :height="1800" v-model="mpackage.itinerary_es" id='itinerary_es'></tinymce>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Incluye / No Incluye:">
                        <tinymce readonly :height="1000" v-model="mpackage.incorporate_es" id='incorporate_es'></tinymce>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Duración:">
                        <b-form-input disabled v-model="mpackage.duration_es"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Precio Anterior:">
                        <b-form-input disabled class="text-right" type="number" step="any" v-model="mpackage.price_old_es"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Precio:">
                        <b-form-input disabled class="text-right" type="number" step="any" v-model="mpackage.price_es"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Video:">
                        <b-form-input disabled v-model="mpackage.video"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Favorito:">
                        <b-form-select disabled v-model="mpackage.favorite" :options="favorite"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Puntuación:">
                        <b-form-select disabled v-model="mpackage.score" :options="score"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="8">
                      <b-form-group label="Ubicación:">
                        <b-form-input disabled v-model="mpackage.location"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Estado:">
                        <b-form-select disabled v-model="mpackage.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="12">
                      <b-form-group label="Especificación:">
                        <tinymce readonly :height="800" v-model="mpackage.specification_es" id='specification_es'></tinymce>
                        <small v-if="errors.specification_es"  class="form-text text-danger" >Ingrese un especificación</small>
                      </b-form-group>
                    </b-col> -->
                    
                    
                  </b-row>
                </b-tab>
                <b-tab title="Ingles">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Category:">
                        <b-form-select disabled v-model="mpackage.id_package_category" :options="package_category_en" ></b-form-select>
                        <small v-if="errors.id_package_category"  class="form-text text-danger" >Seleccione una categoria</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Code:">
                        <b-form-input disabled v-model="mpackage.code" ></b-form-input>
                        <small v-if="errors.code"  class="form-text text-danger" >Ingrese un codigo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Title:">
                        <b-form-input disabled v-model="mpackage.title_en" ></b-form-input>
                        <small v-if="errors.title_en"  class="form-text text-danger" >Ingrese un titulo</small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Description:">
                        <tinymce readonly :height="1000" v-model="mpackage.description_en" id='description_en'></tinymce>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Itinerary:">
                        <tinymce readonly :height="1800" v-model="mpackage.itinerary_en" id='itinerary_en'></tinymce>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="Includes / Does not include:">
                        <tinymce readonly :height="1000" v-model="mpackage.incorporate_en" id='incorporate_en'></tinymce>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Duration:">
                        <b-form-input disabled v-model="mpackage.duration_en"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Previous Price:">
                        <b-form-input disabled class="text-right" type="number" step="any" v-model="mpackage.price_old_en"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Price:">
                        <b-form-input disabled class="text-right" type="number" step="any" v-model="mpackage.price_en"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Video:">
                        <b-form-input disabled v-model="mpackage.video"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Favorito:">
                        <b-form-select disabled  v-model="mpackage.favorite" :options="favorite"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Score:">
                        <b-form-select disabled v-model="mpackage.score" :options="score"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="8">
                      <b-form-group label="Location:">
                        <b-form-input disabled v-model="mpackage.location"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Estado:">
                        <b-form-select disabled v-model="mpackage.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Imagenes">
                  <b-row>
                    <b-col md="6">
                      <b-form-group label="Imagen Principal:" >
                          <b-img style="max-height:400px;" fluid :src="url_base+mpackage.image"></b-img>
                      </b-form-group>
                    </b-col>
                    <b-col md="6"></b-col>
             
                    <b-col md="12"></b-col>
                    <b-col md="12"><strong>Mas Fotos:</strong></b-col>
                    <b-col md="12"></b-col>
                    <b-col md="3" v-for="(item, it) in images" :key="it">
                      <b-img class="img-fluid" :src="url_base+item.image"></b-img>
                    </b-col>
                  </b-row>
                </b-tab>

              </b-tabs>

              <b-row>

                <b-col md="12"></b-col>
                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/paquete/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>



    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";

// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props:["id_package"],
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Package',
      role: 5,
      mpackage: {
        id_package: '',
        id_package_category: '',
        code: '',
        title_es: '',
        description_es: '',
        itinerary_es:'',
        incorporate_es:'',
        duration_es: '',
        price_old_es: '0.00',
        price_es: '0.00',
        specification_es: '',
        title_en: '',
        description_en: '',
        itinerary_en:'',
        incorporate_en:'',
        duration_en: '',
        price_old_en:'0.00',
        price_en: '0.00',
        specification_en: '',
        image: '',
        location:'',
        score:5,
        image_change:'',
        video: '',
        state: 1,
        favorite: 0,
      },
      file:null,
      images: [],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      favorite:[
        {value:'1',text:'SI'},
        {value:'0',text:'NO'},
      ],
      score:[
        {value:1, text:'1'},
        {value:2, text:'2'},
        {value:3, text:'3'},
        {value:4, text:'4'},
        {value:5, text:'5'},
      ],

      package_category_en:[],
      package_category_es:[],

      errors: {
        id_package_category: false,
        code: false,
        

        title_es: false,
        description_es: false,
        specification_es: false,
        description_es: false,
        duration_es: false,
        price_es: false,

        title_en: false,
        description_en: false,
        specification_en: false,
        description_en: false,
        duration_en: false,
        price_en: false,

        image: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ListPackageCategory();
    this.ViewPackage();
  },
  methods: {
    EditPackage,
    Validate,
    onFileChange,
    ListPackageCategory,
    ViewPackage,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function onFileChange(e) {
  this.mpackage.image_change = e.target.files[0];
}

function ViewPackage() {
  let me = this;
  let id_package = je.decrypt(this.id_package);
  let url = me.url_base + "package/view/"+id_package;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mpackage.id_package = response.data.result.package.id_package;
        me.mpackage.id_package_category = response.data.result.package.id_package_category;
        me.mpackage.code = response.data.result.package.code;
        me.mpackage.title_es = response.data.result.package.title_es;
        me.mpackage.description_es = response.data.result.package.description_es;
        me.mpackage.itinerary_es = response.data.result.package.itinerary_es;
        me.mpackage.incorporate_es = response.data.result.package.incorporate_es;
        me.mpackage.price_old_es = response.data.result.package.price_old_es;
        me.mpackage.duration_es = response.data.result.package.duration_es;
        me.mpackage.price_es = response.data.result.package.price_es;
        me.mpackage.specification_es = response.data.result.package.specification_es;
        me.mpackage.title_en = response.data.result.package.title_en;
        me.mpackage.description_en = response.data.result.package.description_en;
        me.mpackage.itinerary_en = response.data.result.package.itinerary_en;
        me.mpackage.incorporate_en = response.data.result.package.incorporate_en;
        me.mpackage.price_old_en = response.data.result.package.price_old_en;
        me.mpackage.duration_en = response.data.result.package.duration_en;
        me.mpackage.price_en = response.data.result.package.price_en;
        me.mpackage.specification_en = response.data.result.package.specification_en;
        me.mpackage.image = response.data.result.package.image;
        me.mpackage.video = response.data.result.package.video;
        me.mpackage.location = response.data.result.package.location;
        me.mpackage.score = response.data.result.package.score;
        me.mpackage.state = response.data.result.package.state;
        me.mpackage.favorite = response.data.result.package.favorite;

        me.images = response.data.result.photos;

      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function ListPackageCategory() {
  let me = this;
  let url = me.url_base + "packages-category/select-active";
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      me.package_category_es.push({ value:'',text:'-- Seleccione una categoria --' });
      me.package_category_en.push({ value:'',text:'-- Seleccione una categoria --' });
      if (response.data.status == 200) {
          for (let index = 0; index < response.data.result.length; index++) {
            const element = response.data.result[index];
            me.package_category_es.push({value:element.id_package_category, text:element.title_es});
            me.package_category_en.push({value:element.id_package_category, text:element.title_en});
          }
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function EditPackage() {
  let me = this;
  let url = me.url_base + "package/edit";
  let data = new FormData();
  data.append("id_package", me.mpackage.id_package);
  data.append("id_package_category", me.mpackage.id_package_category);
  data.append("code", me.mpackage.code);
  data.append("title_es", me.mpackage.title_es);
  data.append("description_es", me.mpackage.description_es);
  data.append("duration_es", me.mpackage.duration_es);
  data.append("price_es", me.mpackage.price_es);
  data.append("specification_es", me.mpackage.specification_es);

  data.append("title_en", me.mpackage.title_en);
  data.append("description_en", me.mpackage.description_en);
  data.append("duration_en", me.mpackage.duration_en);
  data.append("price_en", me.mpackage.price_en);
  data.append("specification_en", me.mpackage.specification_en);

  data.append("image", me.mpackage.image_change);
  data.append("video", me.mpackage.video);
  data.append("state", me.mpackage.state);
  data.append("favorite", me.mpackage.favorite);
  
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
       
          me.mpackage.image = response.data.result.image;
          me.mpackage.image_change = '';
          me.file = null;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {

  this.errors.id_package_category = this.mpackage.id_package_category.length == 0 ? true : false;
  this.errors.code = this.mpackage.code.length == 0 ? true : false;

  this.errors.title_es = this.mpackage.title_es.length == 0 ? true : false;
  this.errors.description_es = this.mpackage.description_es.length == 0 ? true : false;
  this.errors.duration_es = this.mpackage.duration_es.length == 0 ? true : false;
  this.errors.price_es = this.mpackage.price_es.length == 0 ? true : false;
  this.errors.specification_es = this.mpackage.specification_es.length == 0 ? true : false;

  this.errors.title_en = this.mpackage.title_en.length == 0 ? true : false;
  this.errors.description_en = this.mpackage.description_en.length == 0 ? true : false;
  this.errors.duration_en = this.mpackage.duration_en.length == 0 ? true : false;
  this.errors.price_en = this.mpackage.price_en.length == 0 ? true : false;
  this.errors.specification_en = this.mpackage.specification_en.length == 0 ? true : false;

  this.errors.image = this.mpackage.image.length == 0 ? true : false;
  this.errors.state = this.mpackage.state.length == 0 ? true : false;

  if (this.errors.id_package_category) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.code) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  if (this.errors.title_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.description_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.duration_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.price_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.specification_es) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en español esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  if (this.errors.title_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.description_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.duration_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.price_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.specification_en) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios en ingles esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  if (this.errors.image) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }  

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar el paquete ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditPackage();
    }
  });
}
</script>
